<template>
    <v-card class="bg_color--5"
        style="width: 80vw; height: 80vh; top: 50%; left: 50%; transform: translate(-50%, -50%); position: absolute;">
        <v-card-title>
            File Uploader
        </v-card-title>
        <v-card-text>

            <v-container fluid>
                <v-row>
                    <v-col cols="4">
                        <v-file-input v-model="selectedFile" label="Select a File" clearable
                            @change="getFileFormat()"></v-file-input>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="filename" v-model="nameFile" clearable></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="format" v-model="formatFile" disabled></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-combobox prepend-icon="mdi-database" :items="database" v-model="selectedDatabase">
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-btn tile block v-on:click="uploadFile()" :disabled="!selectedFile || !nameFile || !formatFile"
                            :loading="loadingFile">Upload</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field label="generated url" v-model="urlFile" disabled></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-btn tile block v-on:click="downloadFile()" :loading="loadingFile">Download</v-btn>
                    </v-col>
                </v-row>
                <!-- <v-row>
        <v-col>
            <img v-if="imageUrl != null" :src="imageUrl" alt="Uploaded Image" />
        </v-col>
      </v-row> -->
            </v-container>
        </v-card-text>
    </v-card>
</template>
  
<script>
import axios from 'axios'
import { fetchMethod } from '../../plugins/restAPI';
export default {
    data() {
        return {
            selectedFile: null,
            nameFile: '',
            formatFile: '',
            imageUrl: null,
            urlFile: '',
            loadingFile: false,
            database: [
                'dephub',
                'sibernetik',
            ],
            urls: {
                'dephub': 'https://i-motion.dephub.go.id/inplatform/dephubmarine/uploads/',
                // 'dephub' : 'http://192.168.58.245:3000/inplatform/dephubmarine/uploads/',
                'sibernetik': 'https://inplatform.sibernetik.co.id/siber-web/uploads/',
            },
            selectedDatabase: 'sibernetik',
        };
    },
    methods: {
        async fetchData(url) {
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const contentType = response.headers.get('Content-Type');

            if (contentType.includes('application/json')) {
                return await response.json();
            } else if (contentType.includes('text/')) {
                // This will handle text/plain, text/html, text/css, etc.
                if (contentType.includes('vcard')) {
                    return await response.blob();
                } else {
                    return await response.text();
                }
            } else if (contentType.includes('image/') ||
                contentType.includes('audio/') ||
                contentType.includes('video/') ||
                contentType.includes('application/pdf') ||
                contentType.includes('application/msword') ||
                contentType.includes('application/vnd.openxmlformats-officedocument') ||
                contentType.includes('application/vnd.ms-excel') ||
                contentType.includes('application/zip') ||
                contentType.includes('application/x-rar-compressed')) {
                // This will handle common binary data types like images, audio, video, documents, etc.
                return await response.blob();
            } else if (contentType.includes('application/octet-stream')) {
                // For general binary data
                return await response.arrayBuffer();
            } else {
                throw new Error(`Unhandled content type: ${contentType}`);
            }
        },
        downloadFile() {
            let url = this.urlFile
            fetchMethod(url).then(res => {
                const a = document.createElement('a');
                a.href = URL.createObjectURL(res);
                a.download = url.split('/')[url.split('/').length - 1].split('.')[0];
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
        },
        getFileFormat() {
            if (this.selectedFile) this.formatFile = '.' + this.selectedFile.name.split('.')[1]
        },
        resetFields() {
            this.selectedFile = null
            this.nameFile = ''
            this.formatFile = ''
        },
        uploadFile() {
            this.loadingFile = true
            if (!this.selectedFile) {
                this.loadingFile = false
                return
            };

            let staticFile = JSON.stringify(['test123'])

            const formData = new FormData();
            formData.append("filename", this.nameFile + this.formatFile);
            formData.append("file", this.selectedFile);
            // console.log(this.selectedFile)
            console.log(this.selectedFile, this.formatFile)
            // Make a POST request to your REST API endpoint to upload the file
            // You can use Axios, fetch, or any other HTTP library here
            // Example with Axios:
            axios.post(this.urls[this.selectedDatabase], formData)
                .then(response => {
                    this.loadingFile = false
                    this.urlFile = this.urls[this.selectedDatabase] + this.nameFile + this.formatFile
                    this.resetFields()
                    console.log("File uploaded successfully:", response.status, response.data);
                    // this.imageUrl = 'https://inplatform.sibernetik.co.id/siber-web/images/' + 'testimage.png'
                    // Handle success
                })
                .catch(error => {
                    this.loadingFile = false
                    console.error("Error uploading file:", error);
                    // Handle error
                });
        },
    },
};
</script>
  
<style scoped>
/* Add your component-specific styles here */
</style>
  